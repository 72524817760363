'use client';

import Image from 'next/image';

import { Image404 } from '@/assets';
import { Buttons } from '@/components';
import { useRouter } from '@/provider/RouterProvider';

export default function Error() {
  const router = useRouter();
  return (
    <div className="flex w-full min-h-[calc(100vh-100px)] items-center justify-center">
      <div className="flex w-1/2 items-center justify-center lg:hidden">
        <Image className="mr-10 min-w-[426px]" src={Image404} alt="error" />
      </div>
      <div className="ml-10 lg:m-0 sm:p-5">
        <h1 className="m-0 mb-8 text-[60px] font-bold sm:text-[30px]">Page not found</h1>
        <p className="m-0 mb-4 text-20 font-normal text-gray-500">
          There’s nothing to see here. You can try these suggestion
        </p>
        <p className="flex items-center text-20 font-normal text-gray-500">
          <div className="mx-3 h-1 w-1 bg-gray-500" /> Check your connection
        </p>
        <p className="flex items-center text-20 font-normal text-gray-500">
          <div className="mx-3 h-1 w-1 bg-gray-500" /> Try reloading the page
        </p>
        <p className="flex items-center text-20 font-normal text-gray-500">
          <div className="mx-3 h-1 w-1 bg-gray-500" /> Use another keyword
        </p>
        <p className="flex items-center text-20 font-normal text-gray-500">
          <div className="mx-3 h-1 w-1 bg-gray-500" /> Check your connection
        </p>
        <p className="mb-8 flex items-center text-20 font-normal text-gray-500">
          <div className="mx-3 h-1 w-1 bg-gray-500" /> Go back to your previous page
        </p>
        <div className="sm:w-full sm:text-center">
          <Buttons
            onClick={() => router.push('/')}
            size="lg"
            className="text-15 font-semibold text-white"
          >
            Back to Home
          </Buttons>
        </div>
      </div>
    </div>
  );
}
